import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Placeholder from "../../images/show-placeholder.png"
import {
  PlayIcon,
  PauseIcon,
  PlusIcon,
  CheckIcon,
} from "@heroicons/react/solid"
// import AudioPlayer from "./audio-player"
import { StationContext } from "../../context/station-context"
import moment from "moment"
import { convertUTCToTimezone } from "../../utils/functions"
import "moment-timezone"
 
export default function TinyEpisodeRow({ episode, index, showPlayer = true }) {
  const {
    setIsPlaying,
    currentTrack,
    setCurrentTrack,
    addTrackToPlaylist,
    isPlaying,
    playlist,station
  } = React.useContext(StationContext)
  const [inPlayList, setInPlayList] = React.useState(false)

  const image = getImage(episode.node.featuredImg)
  const title = episode.node.program.name || episode.node.id
  const start = convertUTCToTimezone(
    episode.node.show_start,
    null,
    station.timezone
  )
  const startStream = convertUTCToTimezone(
    episode.node.show_start,
    null,
    station.timezone,
    ""
  )
  React.useEffect(() => {
    const test = playlist.filter(item => item.node.original_id === episode.node.original_id)
    if (test.length > 0) {
      setInPlayList(true)
    } else {
      setInPlayList(false)
    }
  }, [playlist])

  // const streamUrl = `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/${startStream}/${episode.node.duration}/master.m3u8?unique=sdfasdfasfadsf`

  const streamUrl = `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/aac_96/${episode.node.local}/${episode.node.duration}/playlist.m3u8?unique=website`
  return moment().isAfter(startStream) ? (
    <article
      className="episode-list-item grid gap-4 mb-2 md:mb-8 md:pb-8 w-full grid-cols-6 border-b-3 border-black"
      itemScope
      itemType="http://schema.org/Article"
    >
      <div
        className={`col-span-1 lg:col-span-1 pt-1 `}
      >
        {image ? (
          <GatsbyImage
            className={"w-8 h-auto "}
            image={image}
            alt={episode.node.program.name}
          />
        ) : (
          <img className={"w-8 h-auto "} src={Placeholder} alt={episode.node.program.name} />
        )}
      </div>

      <header className={"col-span-5"}>
        <h2 className={"text-2xl mb-1 font-bold text-gray-200 "}>
          <Link to={`/${episode.node.program.slug}`} itemProp="url">
            <span itemProp="headline">{title}</span>
          </Link>
        </h2>
        <p className={"text-sm font-light text-gray-200"}>{start}</p>

        {showPlayer &&
        moment().isAfter(startStream) &&
        station.hls_stream ? (
          <div className={"w-full mt-4"}>
            <button
              onClick={() => {
                if (
                  isPlaying &&
                  currentTrack &&
                  currentTrack.node.original_id === episode.node.original_id
                ) {
                  setIsPlaying(!isPlaying)
                } else {
                  setCurrentTrack({
                    ...episode,
                    url: streamUrl,
                    start: start,
                    isLive: false,
                  })
                }
              }}
            >
              {isPlaying === true &&
              currentTrack &&
              currentTrack.node.original_id === episode.node.original_id ? (
                <PauseIcon className={"w-8 h-8  text-blue-200 mr-4"} />
              ) : (
                <PlayIcon className={"w-8 h-8  text-gray-200  mr-4"} />
              )}
            </button>
            <button
              onClick={() => {
                if (inPlayList === false) {
                  addTrackToPlaylist({
                    ...episode,
                    url: streamUrl,
                    start: start,
                    isLive: false,
                  })
                }
              }}
            >
              {inPlayList === true ? (
                <CheckIcon className={"w-8 h-8 "} />
              ) : (
                <PlusIcon className={"w-8 h-8 text-gray-200"} />
              )}
            </button>
          </div>
        ) : null}
      </header>
    </article>
  ) : null
}
