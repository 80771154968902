import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function FeaturedPost({ post }) {
  const image = post.frontmatter.featuredImage && getImage(post.frontmatter.featuredImage)
  const title = post.frontmatter.title || post.fields.slug
  return (
    <article
      className="post-list-item w-full mb-12"
      itemScope
      itemType="http://schema.org/Article"
    >
      <GatsbyImage image={image} alt={title} />
      <header className={"text-center mt-8 mb-8"}>
        <h2 className="text-4xl font-semibold mb-4">
          <Link to={post.fields.slug} itemProp="url">
            <span itemProp="headline">{title}</span>
          </Link>
        </h2>
        <small>{post.frontmatter.date}</small>
      </header>
      <section>
        <p
        className={'text-center'}
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.description || post.excerpt,
          }}
          itemProp="description"
        />
      </section>
    </article>
  )
}
