import * as React from "react"
import { graphql } from "gatsby"
import ProgramRow from "../components/program-row"
import Post from "../components/homepage/post"
import FeaturedPost from "../components/homepage/featured-post"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TinyEpisodeRow from "../components/homepage/tiny-epiwsode-row"
import moment from "moment"
import "moment-timezone"
import { convertUTCToTimezone } from "../utils/functions"
import { StationContext } from "../context/station-context"
 

export const queryProgram = graphql`
  fragment Program on program {
    id
    name
    media_id
    introduction
    slug
    original_id
    presenter_string
    genre_string
    slots {
      created_at
      duration
      hour_end
      hour_start
      minute_end
      minute_start
      program_id
      seconds_from_sunday
      station_id
      updated_at
      weekday_end
      weekday_start
    }
    bio
    featuredImg {
      url
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export const queryStation = graphql`
  fragment Station on station {
    call_sign
    enabled
    hls_stream
    icecast_stream
    id
    media_id
    name
    owner_id
    public
    slug
    timezone
    featuredImg {
      url
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`

export const query = graphql`
  fragment Episode on episode {
    id
    updated_at
    station_id
    slot_id
    show_start_date
    show_start
    show_end_date
    timestamp
    timestampEnd
    local
    original_id
    show_end
    program_id
    created_at
    duration
    featuredImg {
      url
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    program {
      ...Program
    }
  }
`

export const querySlot = graphql`
  fragment Slot on slot {
    created_at
    duration
    hour_end
    hour_start
    id
    is_active
    minute_end
    minute_start
    original_id
    program_id
    seconds_from_sunday
    station_id
    weekday_end
    weekday_start
    episodes {
      id
      updated_at
      station_id
      slot_id
      show_start_date
      show_start
      show_end_date
      show_end
      program_id
      created_at
      duration
    }
    featuredImg {
      url
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

const HomePage = ({ data, location }) => {
  const {station} = React.useContext(StationContext)
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes
  const programs = data.allProgram.edges
  const episodes = data.allEpisode.edges

  const [actualEpisodes, setActualEpisodes] = React.useState([])

  React.useEffect(() => {
    const newEpisodes = episodes.filter(item => {
      const startStream = convertUTCToTimezone(
        item.node.show_start,
        null,
        station.timezone,
        ""
      )
      if (moment().isAfter(startStream)) {
        return item
      }
    })
    setActualEpisodes(newEpisodes)
  }, [episodes, setActualEpisodes])

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All Programs" />

        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All posts" />
      <div className="homepage w-full">
        <div className="hero-leader lg:flex lg:gap-4 xxl:gap-12">
          <div className="w-full lg:w-2/3"> 
            <ol style={{ listStyle: `none` }}>
              {posts.map((post, i) => {
                if (i === 0) {
                  return <FeaturedPost key={post.fields.slug} post={post} />
                } else {
                  return <Post key={post.fields.slug} post={post} />
                }
              })}
            </ol>
          </div>
          {actualEpisodes && actualEpisodes.length > 0 && (
            <div className="w-full lg:w-1/3 text-white bg-brand p-4 lg:p-8">
              <h3 className="text-sm uppercase text-center mb-8 font-semibold">
                LATEST EPISODES
              </h3>
              {actualEpisodes &&
                actualEpisodes.length > 0 &&
                actualEpisodes.map((episode, i) => {
                  return i < 5 ? (
                    <TinyEpisodeRow key={`home-${i}`} episode={episode} index={i} />
                  ) : (
                    <></>
                  )
                })}
            </div>
          )}
        </div>
      </div>
      <div className="mt-8 bg-gray-100 lg:p-8 p-4 w-full">
        <h3 className="text-xl xl:text-4xl text-center mb-8 font-bold">
          Our Programs
        </h3>
        <ol style={{ listStyle: `none` }}>
          {programs.map(post => {
            return <ProgramRow program={post.node} key={post.node.id} />
          })}
        </ol>
      </div>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { is_home: { eq: true } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          duration
          segment_start
          featuredImage {
            url
            childImageSharp {
              gatsbyImageData(
                width: 900
                height: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          tile: featuredImage {
            url
            childImageSharp {
              gatsbyImageData(
                width: 600
                height: 600
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    allProgram {
      edges {
        node {
          ...Program
        }
      }
    }
    allEpisode(sort: { fields: show_start, order: DESC }) {
      edges {
        node {
          ...Episode
        }
      }
    }
  }
`
