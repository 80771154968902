import React from "react"
import {
  PlayIcon,
  PauseIcon,
  PlusIcon,
  CheckIcon,
} from "@heroicons/react/solid"
// import AudioPlayer from "./audio-player"
import { StationContext } from "../../context/station-context"
import moment from "moment"
import "moment-timezone"

export default function PostSegment({ post, index }) {
  const {
    setIsPlaying,
    currentTrack,
    setCurrentTrack,
    addTrackToPlaylist,
    isPlaying,
    playlist,
    station,
  } = React.useContext(StationContext)
  const [inPlayList, setInPlayList] = React.useState(false)
  console.log("Post", post)
  React.useEffect(() => {
    const test = playlist.filter(item => item.node.original_id === post.id)
    if (test.length > 0) {
      setInPlayList(true)
    } else {
      setInPlayList(false)
    }
  }, [playlist])

  // console.log("Here", episode)
  const streamUrl = `https://hls-server.nicklarosa.net/public/endpoints/ondemand/duration/${station.hls_stream}/aac_96/${post.frontmatter.segment_start}/${post.frontmatter.duration}/playlist.m3u8?unique=website`
  return (
    <article
      className="episode-list-item grid gap-4 mb-2 md:mb-4 md:pb-8 w-full grid-cols-6 border-b-3 border-black"
      itemScope
      itemType="http://schema.org/Article"
    >
      <header className={"col-span-5"}>
        <div className={"w-full mt-4 flex gap-2"}>
          <button
            onClick={() => {
              if (
                isPlaying &&
                currentTrack &&
                currentTrack.node.original_id === post.id
              ) {
                setIsPlaying(!isPlaying)
              } else {
                setCurrentTrack({
                  node: {
                    original_id: post.id,
                    id: post.id,
                    program: {
                      name: "Custom Segment",
                    },
                  },
                  url: streamUrl,
                  start: moment(post.frontmatter.segment_start).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  ),
                  isLive: false,
                })
              }
            }}
          >
            {isPlaying === true &&
            currentTrack &&
            currentTrack.node.original_id === post.id ? (
              <PauseIcon className={"w-8 h-8  text-blue-500 "} />
            ) : (
              <PlayIcon className={"w-8 h-8  text-brand "} />
            )}
          </button>
          <button
            onClick={() => {
              if (inPlayList === false) {
                addTrackToPlaylist({
                  node: {
                    original_id: post.id,
                    id: post.id,
                    program: {
                      name: "Custom Segment",
                    },
                  },
                  url: streamUrl,
                  start: moment(post.frontmatter.segment_start).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  ),
                  isLive: false,
                })
              }
            }}
          >
            {inPlayList === true ? (
              <CheckIcon className={"w-8 h-8 "} />
            ) : (
              <PlusIcon className={"w-8 h-8 text-gray-900"} />
            )}
          </button>
        </div>
      </header>
    </article>
  )
}
