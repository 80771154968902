import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Placeholder from "../../images/show-placeholder.png"
import PostSegment from "../post/post-segment"

export default function Post({ post }) {
  // console.log(post)
  const image = post.frontmatter.featuredImage
    ? getImage(post.frontmatter.featuredImage)
    : null
  const title = post.frontmatter.title || post.fields.slug
  return (
    <article
      className="post-list-item w-full grid grid-cols-5 gap-8 mb-24"
      itemScope
      itemType="http://schema.org/Article"
    >
      <div className="col-span-5 lg:col-span-2 ">
        <Link to={post.fields.slug} itemProp="url">
          {image ? (
            <GatsbyImage image={image} alt={title} />
          ) : (
            <img src={Placeholder} alt={title} />
          )}
        </Link>
      </div>
      <div className="col-span-5 lg:col-span-3 ">
        <Link to={post.fields.slug} itemProp="url">
          <header className={"mb-4"}>
            <h2 className="text-2xl font-semibold">
              <span itemProp="headline">{title}</span>
            </h2>
            <small>{post.frontmatter.date}</small>
          </header>
        </Link>
        <section>
          <p
            dangerouslySetInnerHTML={{
              __html: post.frontmatter.description || post.excerpt,
            }}
            itemProp="description"
          />
        </section>
        {post.frontmatter.duration && post.frontmatter.segment_start && (
          <PostSegment post={post} />
        )}
      </div>
    </article>
  )
}
